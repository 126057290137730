
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FormInputState } from '@/models/Forms/FormState';
import { CheckBoxOptions, FormOptionValue } from '@/models/Forms/FormOption';

@Component<CheckboxComponent>({})
export default class CheckboxComponent extends Vue {
  @Prop()
  id?: string;

  @Prop()
  value?: FormOptionValue | FormOptionValue[];

  @Prop({ default: '' })
  label!: string;

  @Prop({
    default: () => []
  })
  checkboxItems!: CheckBoxOptions[];

  @Prop({ default: 'Please select an option' })
  errorText!: string;

  @Prop({ default: true })
  isBinary!: boolean;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: false })
  readonly!: boolean;

  mounted(): void {
    this.emitValidationState();
  }

  isPristine = true;

  get checkboxValue(): boolean | FormOptionValue | FormOptionValue[] {
    if (this.isBinary) {
      return this.value || false;
    }
    return this.value || [];
  }

  set checkboxValue(newVal: FormOptionValue | FormOptionValue[] | boolean) {
    this.$emit('input', newVal);
  }

  get showError(): boolean {
    return !this.isPristine && !this.isValid;
  }

  get isValid(): boolean {
    if (!this.required || !this.checkboxItems) return true;
    return !!this.checkboxValue;
  }

  @Watch('isValid')
  validityChange(): void {
    this.emitValidationState();
  }

  emitValidationState(): void {
    this.$emit(
      'validationState',
      new FormInputState({
        isValid: this.isValid,
        errorMessage: this.errorText
      })
    );
  }

  onBlur(): void {
    this.isPristine = false;
    this.$emit('blur');
  }
}
