
import { Component, Prop, Vue } from 'vue-property-decorator';
import IconComponent from '@/components/IconComponent.vue';
import { DropdownOptions, NestedDropDown } from '@/models/Forms/FormOption';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
@Component<DropdownNestedComponent>({
  components: { IconComponent, FontAwesomeIcon }
})
export default class DropdownNestedComponent extends Vue {
  @Prop()
  id?: string;

  @Prop()
  value?: string;

  @Prop()
  prefill?: string;

  @Prop({ default: () => '' })
  label!: string;

  @Prop({
    default: 'Select One'
  })
  placeholder!: string | undefined;

  @Prop()
  dropDownOptions!: NestedDropDown[];

  open = false;
  noHover = -1;
  itemIndex = this.noHover;

  created(): void {
    //prefill from another entity
    if (this.prefill) {
      this.setDropdownValue(this.prefill);
    }
  }

  toggle(): void {
    this.open = !this.open;
  }

  hoverEventOnSubDropDown(index: number): void {
    this.itemIndex = index;
  }

  get dropdownValue(): DropdownOptions | string {
    return this.value || '';
  }

  setDropdownValue(val: string): void {
    this.open = false;
    this.$emit('input', val);
  }

  showCheckMark(val: string): boolean {
    return this.dropdownValue === val;
  }

  onBlur(): void {
    this.open = false;
  }
}
