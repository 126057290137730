var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: _vm.id } }, [
    _vm.label
      ? _c("label", { staticClass: "field-labels" }, [
          _vm._v(" " + _vm._s(_vm.label) + " "),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "flex gap-2 h-9 font-lato" },
      _vm._l(_vm.checkboxItems, function (checkItem, index) {
        return _c(
          "label",
          { key: index, staticClass: "inline-flex items-center mr-4" },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.checkboxValue,
                  expression: "checkboxValue",
                },
              ],
              attrs: { type: "checkbox", disabled: _vm.readonly },
              domProps: {
                value: checkItem.value,
                checked: Array.isArray(_vm.checkboxValue)
                  ? _vm._i(_vm.checkboxValue, checkItem.value) > -1
                  : _vm.checkboxValue,
              },
              on: {
                blur: _vm.onBlur,
                change: function ($event) {
                  var $$a = _vm.checkboxValue,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = checkItem.value,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.checkboxValue = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.checkboxValue = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.checkboxValue = $$c
                  }
                },
              },
            }),
            _c("div", { staticClass: "ml-2 text-sm" }, [
              _vm._v(_vm._s(checkItem.label)),
            ]),
          ]
        )
      }),
      0
    ),
    _vm.showError
      ? _c("div", { staticClass: "mt-2 text-xs leading-tight text-red-500" }, [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }