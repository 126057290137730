
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { FormInputState } from '@/models/Forms/FormState';
import { RadioOptions, FormOptionValue } from '@/models/Forms/FormOption';

@Component<RadioComponent>({})
export default class RadioComponent extends Vue {
  @Prop()
  id?: string;

  @Prop()
  value?: FormOptionValue;

  @Prop({ default: '' })
  label!: string;

  @Prop({
    default: () => []
  })
  radioItems!: RadioOptions[];

  @Prop({ default: 'Please select an option' })
  errorText!: string;

  @Prop({ default: false })
  required!: boolean;

  @Prop({ default: false })
  readonly!: boolean;

  isPristine = true;

  get radioValue(): FormOptionValue | undefined {
    return this.value;
  }

  set radioValue(newVal: FormOptionValue | undefined) {
    this.$emit('input', newVal);
  }

  get showError(): boolean {
    return !this.isPristine && !this.isValid;
  }

  get isValid(): boolean {
    if (!this.required || !this.radioItems) return true;
    return (
      this.radioItems.findIndex(
        (radioItem) => radioItem.value === this.radioValue
      ) > -1
    );
  }

  @Watch('isValid')
  emitValidationState(): void {
    this.$emit(
      'validationState',
      new FormInputState({
        isValid: this.isValid,
        errorMessage: this.errorText
      })
    );
  }

  mounted(): void {
    this.emitValidationState();
  }

  onBlur(): void {
    this.isPristine = false;
    this.$emit('blur');
  }
}
