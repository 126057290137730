var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: _vm.id } }, [
    _vm.label
      ? _c("label", { staticClass: "field-labels" }, [
          _vm._v(_vm._s(_vm.label)),
        ])
      : _vm._e(),
    _c(
      "div",
      {
        staticClass:
          "flex justify-center w-full gap-2 pl-2 text-sm text-gray-700 border rounded appearance-none h-9 focus:outline-none focus:shadow-outline",
        class: { "bg-gray-100": _vm.readonly },
      },
      _vm._l(_vm.radioItems, function (radioItem, index) {
        return _c(
          "label",
          {
            key: index,
            staticClass: "flex items-center w-full border-r last:border-r-0",
          },
          [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.radioValue,
                  expression: "radioValue",
                },
              ],
              attrs: { disabled: _vm.readonly, type: "radio" },
              domProps: {
                value: radioItem.value,
                checked: _vm._q(_vm.radioValue, radioItem.value),
              },
              on: {
                blur: _vm.onBlur,
                change: function ($event) {
                  _vm.radioValue = radioItem.value
                },
              },
            }),
            _c("span", { staticClass: "mx-2 font-normal" }, [
              _vm._v(_vm._s(radioItem.label)),
            ]),
          ]
        )
      }),
      0
    ),
    _vm.showError
      ? _c("div", { staticClass: "mt-2 text-xs text-red-500" }, [
          _vm._v(" " + _vm._s(_vm.errorText) + " "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }